@import "./utilities/variables.scss";

@import "./components/screens/loginScreen.scss";

html {
  background: $theme-blue-main;

  // https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting?rq=1

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

  /* Phone */
  @media only screen and (min-width: 0px) {
    font-size: 13px;
  }

  /* Tablet */
  @media only screen and (min-width: 600px) {
    font-size: 17px;
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #545454 $theme-blue-main;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

*::-webkit-scrollbar-track {
  background: $theme-blue-main;
}

*::-webkit-scrollbar-thumb {
  background-color: #00478127;
  border-radius: 50px;
  border: 3px solid $theme-blue-main;
}

.dark-mode {
  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  background: $dark-theme-bg;
  min-height: 100vh;
  * {
    scrollbar-width: thin;
    scrollbar-color: #505050;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px;
    height: 3px;
  }

  *::-webkit-scrollbar-track {
    background: $dark-theme-bg;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #1a1a1a50;
    border-radius: 50px;
    border: 3px solid transparent;
  }
}

/* REACT CAROUSEL */
.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover,
.carousel .control-dots .dot {
  opacity: 0;
}
.carousel .carousel-status {
  opacity: 0;
}
