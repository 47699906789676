@keyframes blue-bg {
  from {
    background: linear-gradient(#2c77ff, #2c76ffc2);
  }
  to {
    background: linear-gradient(#ffa42cc2, #2c77ff);
  }
}

$theme-blue-main: transparent;
$theme-blue-main-sel: rgb(209, 232, 252);
$theme-grey-main: #c3d7d8;
$theme-grey-main-light: rgb(218, 218, 218);
$theme-yellow-main: #333;
$purple: #2c77ff;
$blue-gradient: linear-gradient(#2c77ff, #2c76ffc2);
$del-red: $purple; //#cd1818cc;
$red: #cd1818cc;
$error: #ff8044;
$box-shadow-bar: -2px 10px 90px -28px rgba(0, 0, 0, 0.29);

$dark-theme-bg: transparent;
$dark-them-bg-shade2: rgb(37, 38, 44);
$dark-theme-bg-light: rgb(46, 47, 51);
$dark-theme-font-color: rgba(240, 248, 255, 0.795);
$dark-theme-yellow-light: rgb(223, 189, 0);
$dark-theme-yellow-dark: rgb(196, 151, 3);
$dark-theme-gradient: linear-gradient(
  $dark-theme-yellow-light,
  $dark-theme-yellow-dark
);
